import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useEffect, useState } from "react";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import { useNavigate, useParams } from "react-router-dom";
import axios from "../../../helper/axios";
import { toast } from "react-toastify";
import IMG_URL from "../../../helper/img";
import { Motion } from "../../Motion";
import { Info } from "../../Info";

export const UploadPaymentReg = () => {
  const [image, setImage] = useState(null);
  const { id } = useParams();
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const handleFileClick = () => {
    const fileInput = document.getElementById("file-input");
    fileInput.click();
  };

  const getData = async () => {
    try {
      const { data } = await axios.get(`/reg/${id}`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      setUser(data);
    } catch (err) {
      console.log(err);
      navigate("/portal/pending-register");
    }
  };

  const handleUpload = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("file", image);

      await axios.post(`/reg/tf/${id}`, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
            authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      toast.success("Document Uploaded");
    } catch (err) {
      console.log(err);
      setLoading(false);
      toast.error(
        err.response.data || "Terjadi kesalahan. Silakan coba lagi nanti."
      );
    }
  };

  useEffect(() => {
    getData();
  }, [loading]);

  return (
    <Motion>
      <Box
        sx={{
          minHeight: { sm: "70vh", xs: "65vh" },
          bgcolor: "white",
          borderRadius: { sm: "40px 40px 0 0", xs: "20px 20px 0 0" },
          display: "flex",
          justifyContent: "center",
          padding: { xs: "20px 0 0", sm: "40px 0 0" },
          position: "relative",
          zIndex: 2,
        }}
      >
        <Info desc="Informasi Tagihan">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              minWidth: { md: "60vw", sm: "70vw", xs: "80vw" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: "10px",
                gap: "5px",
              }}
            >
              <Typography
                sx={{
                  color: "gray",
                  fontSize: "14px",
                }}
              >
                Nama Siswa
              </Typography>
              <Typography sx={{ fontSize: "14px" }}>
                {user?.Nama_Kandidat}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: "10px",
                borderTop: "1px solid",
                borderColor: grey[400],
                gap: "5px",
              }}
            >
              <Typography
                sx={{
                  color: "gray",
                  fontSize: "14px",
                }}
              >
                Status Tagihan
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  color:
                    user?.Status_Pembayaran === "Pending"
                      ? "orange"
                      : user?.Status_Pembayaran === "Confirm"
                      ? "blue"
                      : "green",
                }}
              >
                {user?.Status_Pembayaran === "Pending"
                  ? "Menunggu Pembayaran"
                  : user?.Status_Pembayaran === "Confirm"
                  ? "Menunggu Konfirmasi"
                  : "Lunas"}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                borderTop: "1px solid",
                borderColor: grey[400],
                padding: "10px",
                gap: "5px",
              }}
            >
              <Typography
                sx={{
                  color: "gray",
                  fontSize: "14px",
                }}
              >
                Total Tagihan
              </Typography>
              <Typography sx={{ fontSize: "14px" }}>Rp. 250.000</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                borderTop: "1px solid",
                borderColor: grey[400],
                padding: "10px",
                gap: "5px",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  color: "gray",
                  fontSize: "14px",
                  alignSelf: "start",
                }}
              >
                Upload Bukti Pembayaran
              </Typography>
              {image || user?.Status_Pembayaran !== "Pending" ? (
                <Box
                  sx={{
                    padding: { sm: "20px", xs: "15px" },
                    display: "flex",
                    justifyContent: "center",
                    border: "1px dashed",
                    color: "gray",
                    borderRadius: "5px",
                    cursor: "pointer",
                    maxWidth: "60%",
                    position: "relative",
                  }}
                >
                  <img
                    src={
                      user?.Status_Pembayaran !== "Pending"
                        ? `${IMG_URL}public/${user?.Bukti_Tf}`
                        : URL.createObjectURL(image)
                    }
                    alt="Bukti Pembayaran"
                    style={{ height: "100%", width: "100%", objectFit: "" }}
                  />
                  <Box
                    onClick={() => setImage(null)}
                    sx={{
                      position: "absolute",
                      top: "-12px",
                      right: "-10px",
                      bgcolor: "white",
                      width: "20px",
                      height: "20px",
                      display:
                        user?.Status_Pembayaran !== "Pending" ? "none" : "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "50%",
                      color: "orange",
                    }}
                  >
                    <HighlightOffOutlinedIcon />
                  </Box>
                </Box>
              ) : (
                <>
                  <Box
                    onClick={handleFileClick}
                    sx={{
                      padding: { sm: "20px", xs: "15px" },
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                      border: "1px dashed",
                      color: "gray",
                      borderRadius: "5px",
                      cursor: "pointer",
                      gap: "10px",
                    }}
                  >
                    <CameraAltOutlinedIcon /> Upload Foto
                  </Box>
                  <input
                    type="file"
                    id="file-input"
                    accept=".png, .jpg, .jpeg"
                    style={{ display: "none" }}
                    onChange={(e) => {
                      setImage(e.target.files[0]);
                    }}
                  />
                </>
              )}
            </Box>
            {user?.Status_Pembayaran !== "Pending" ? null : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  borderTop: "1px solid",
                  borderColor: grey[400],
                  padding: "10px",
                  gap: "5px",
                }}
              >
                <Button
                  variant="contained"
                  disabled={image == null}
                  sx={{ textTransform: "none" }}
                  onClick={() => {
                    handleUpload();
                    setImage(null);
                  }}
                >
                  {loading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    "Kirim"
                  )}
                </Button>
              </Box>
            )}
          </Box>
        </Info>
      </Box>
    </Motion>
  );
};
