import { Box, Typography } from "@mui/material"

export const Header = () => {
    return (
        <Box sx={{
            height: { sm: '30vh', xs: '35vh'},
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'white'
        }}
        position='sticky'
        top='0'
        >
            <Box
                sx={{
                    width: '100px',
                    height: '100px',
                }}
                >
                    <img
                        src="https://darussalamkasomalang.sch.id/images/identities/logo.png"
                        alt="logo"
                        style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        }}
                    />
            </Box>
            <Typography fontSize={{ sm: '12px', xs: '10px' }}>
                Pendaftaran Santri Baru
            </Typography>
            <Typography fontSize={{ sm: '18px', xs: '16px' }} fontWeight='bold'>
                Tahun Ajaran 2025-2026
            </Typography>
        </Box>
    )
}