import { Box } from "@mui/material";
import { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { Navbar } from "../../components/Portal/Navbar";
import axios from "../../helper/axios";
import { login } from "../../redux/userSlice";
import { useDispatch } from "react-redux";

export const NavbarLogin = () => {
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();

  const keepLogin = async () => {
    try {
      const { data } = await axios.get(`/auth`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      dispatch(login({ ...data.result }));
    } catch (err) {
      localStorage.removeItem("token");
      window.location.href = "/portal/login";
      console.log(err);
    }
  };

  useEffect(() => {
    token ? keepLogin() : console.log("login first");
  });
  return token ? (
    <Box>
      <Navbar />
      <Outlet />
    </Box>
  ) : (
    <Navigate to="/portal/login" />
  );
};
