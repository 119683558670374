import { Box, useTheme } from "@mui/material"
import { BackButton } from "../components/BackButton"
import { Header } from "../components/Header"
import { Tutorial } from "../components/Tutorial"

export const TutorialPage = () => {
    const theme = useTheme()
    return(
        <Box sx={{ height: '100vh', bgcolor: theme.palette.primary.main }}>
            <BackButton to={"/"} />
            <Header />
            <Tutorial />
        </Box>
    )
}