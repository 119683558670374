import { Outlet, useLocation } from "react-router-dom";
import { initAnalytics, trackPageView } from "../components/useAnalytics";
import { useEffect } from "react";

export const Analytics = () => {
  const location = useLocation();
  useEffect(() => {
    initAnalytics();
    trackPageView(location.pathname);
  }, [location]);
  return <Outlet />;
};
