import { Box, Button, CircularProgress, Typography,
} from "@mui/material"
import { grey } from "@mui/material/colors"
import { Motion } from "../Motion";
import { Info } from "../Info";
import axios from "../../helper/axios";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import IMG_URL from "../../helper/img";

export const ConfirmPayment = () => {
    const { token } = useParams()
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState({})
    const navigate = useNavigate()
    const getData = async () => {
        try {
            const { data } = await axios.get('/reg', {
                headers: {
                  authorization: `Bearer ${token}`,
                },
            })
            setUser(data)
            if (data.Status_Pembayaran !== 'Confirm') throw { message: "Pembayaran Sudah Dikonfirmasi"}
        } catch (err) {
            console.log(err);
            toast.error(
                err.message || err.response?.data || 'Terjadi kesalahan. Silakan coba lagi nanti.'
            );
            navigate('/')
        }
    }

    const onConfirm = async () => {
        try {
            setLoading(true);
            await axios.post('/reg/confirm/paid', {}, {
                headers: {
                  authorization: `Bearer ${token}`,
                },
            })
            setLoading(false);
            toast.success("Konfirmasi Berhasil");
        } catch (err) {
            console.log(err);
            setLoading(false);
            toast.error(
                err.response.data || 'Terjadi kesalahan. Silakan coba lagi nanti.'
            );
        }
    }

    const onCancel = async () => {
        try {
            setLoading(true);
            await axios.post('/reg/confirm/pending', {}, {
                headers: {
                  authorization: `Bearer ${token}`,
                },
            })
            setLoading(false);
            toast.success("Konfirmasi Berhasil");
        } catch (err) {
            console.log(err);
            setLoading(false);
            toast.error(
                err.response.data || 'Terjadi kesalahan. Silakan coba lagi nanti.'
            );
        }
    }

    useEffect(() => {
        getData()
    }, [])
    return (
        <Motion>
            <Box sx={{ 
                minHeight: { sm: '70vh', xs: '65vh' },
                bgcolor:'white',
                borderRadius:{ sm: "40px 40px 0 0", xs: "20px 20px 0 0" },
                display: 'flex',
                justifyContent: 'center',
                padding: { xs: '20px 0 0', sm: '40px 0 0' },
                position: 'relative',
                zIndex: 2
            }}
            >
                <Info desc="Konfirmasi Pembayaran">
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: { md: '60vw', sm: '70vw', xs: '80vw'},
                    }}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                padding: '10px',
                                gap: '5px',
                            }}
                        >
                            <Typography sx={{
                                color: 'gray',
                                fontSize: '14px',
                            }}
                            >
                                Nama Siswa
                            </Typography>
                            <Typography sx={{ fontSize: '14px' }}>
                                {user?.Nama_Kandidat}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                borderTop: '1px solid',
                                borderColor: grey[400],
                                padding: '10px',
                                gap: '5px',
                            }}
                        >
                            <Typography sx={{
                                color: 'gray',
                                fontSize: '14px',
                            }}
                            >
                                Total Pembayaran
                            </Typography>
                            <Typography sx={{ fontSize: '14px' }}>
                                Rp. 250.000
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                borderTop: '1px solid',
                                borderColor: grey[400],
                                padding: '10px',
                                gap: '5px',
                                alignItems: 'center',
                            }}
                        >
                            <Typography sx={{
                                color: 'gray',
                                fontSize: '14px',
                                alignSelf: 'start',
                            }}
                            >
                                Bukti Pembayaran
                            </Typography>
                            <Box
                                sx={{
                                    padding: { sm: '20px', xs: '15px' },
                                    display: 'flex',
                                    justifyContent: 'center',
                                    border: '1px dashed',
                                    color: 'gray',
                                    borderRadius: '5px',
                                    cursor: 'pointer',
                                    maxWidth: '60%',
                                    position: 'relative',
                                }}
                            >
                                <img 
                                    src={`${IMG_URL}public/${user?.Bukti_Tf}`} 
                                    alt="Bukti Pembayaran" 
                                    style={{ height: '100%', width: '100%', objectFit: ''}}
                                    
                                />
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                borderTop: '1px solid',
                                borderColor: grey[400],
                                padding: '10px',
                                gap: '5px',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Button variant="outlined" onClick={onCancel}>{loading ? <CircularProgress size={24} color="inherit" /> : 'Tolak'}</Button>
                            <Button variant="contained" onClick={onConfirm}>{loading ? <CircularProgress size={24} color="inherit" /> : 'Konfirmasi'}</Button>
                        </Box>
                    </Box>
                </Info>
            </Box>
        </Motion>
    )
}