import { Box, Typography, useTheme,
} from "@mui/material"
import { Info } from "./Info"
import { Motion } from "./Motion"
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';

export const Intruction = () => {
    const theme = useTheme()

    const handleOpen = (link) => {
        window.open(link, '_blank')
    }

    return (
        <Motion>
            <Box sx={{ 
                minHeight: { sm: '70vh', xs: '65vh' },
                bgcolor:'white',
                borderRadius:{ sm: "40px 40px 0 0", xs: "20px 20px 0 0" },
                display: 'flex',
                justifyContent: 'center',
                padding: { xs: '20px 0 0', sm: '40px 0 0' },
                position: 'relative',
                zIndex: 2
            }}
            >
                <Info desc="Petunjuk Pendaftaran">
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}>
                        <Box 
                        onClick={() => handleOpen('https://adm.api.darussalamkasomalang.sch.id/public/file/PETUNJUK_DAN_TEKNIS_PSB_2024.pdf')}
                        sx={{
                            display: 'flex',
                            borderBottom: '1px dashed',
                            borderColor: theme.palette.primary.main,
                            width: '100%',
                            minHeight: { sm: '90px', xs: '60px' },
                            alignItems: 'center',
                            padding: '0 10px',
                            gap: { sm: '20px', xs: '10px' },
                            cursor: 'pointer',
                        }}>
                            <PictureAsPdfOutlinedIcon sx={{ fontSize: { sm: '50px', xs: '30px' }, color: '#d63034' }} />
                            <Typography sx={{
                                fontFamily: 'monospace',
                                fontSize: { sm: '18px', xs: '12px' }
                            }}>
                                Petunjuk & Teknis PSB 2025
                            </Typography>
                        </Box>
                        <Box 
                        onClick={() => handleOpen('https://adm.api.darussalamkasomalang.sch.id/public/file/brosur_2024.pdf')}
                        sx={{
                            display: 'flex',
                            borderBottom: '1px dashed',
                            borderColor: theme.palette.primary.main,
                            width: '100%',
                            minHeight: { sm: '90px', xs: '60px' },
                            alignItems: 'center',
                            padding: '0 10px',
                            gap: { sm: '20px', xs: '10px' },
                            cursor: 'pointer'
                        }}>
                            <PictureAsPdfOutlinedIcon sx={{ fontSize: { sm: '50px', xs: '30px' }, color: '#d63034' }} />
                            <Typography sx={{
                                fontFamily: 'monospace',
                                fontSize: { sm: '18px', xs: '12px' }
                            }}>
                                Brosur PSB 2025
                            </Typography>
                        </Box>
                    </Box>
                </Info>
            </Box>
        </Motion>
    )
}