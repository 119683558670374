import { Box, useTheme } from "@mui/material"
import { BackButton } from "../components/BackButton"
import { Header } from "../components/Header"
import { Register } from "../components/Register"
import { CountDown } from "../components/Countdown"

export const RegisterPage = () => {
    const theme = useTheme()
    return(
        <Box sx={{ height: '100vh', bgcolor: theme.palette.primary.main }}>
            <BackButton to={"/"} />
            <Header />
            <Register />
            {/* <CountDown /> */}
        </Box>
    )
}
