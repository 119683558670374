import { Box, Button, CircularProgress } from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import { FieldInput } from "../Field";
import { useState } from "react";
import { Motion } from "../Motion";
import axios from "../../helper/axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const validationSchema = Yup.object({
  email: Yup.string().email("Invalid email").required("Email harus diisi"),
  password: Yup.string().required("Password harus diisi"),
});

export const LoginPortal = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onLogin = async (values) => {
    try {
      setLoading(true);
      const { data } = await axios.post("/auth/login", {
        ...values,
        tahun_pelajaran: 1,
      });
      setLoading(false);
      toast.success("Login Berhasil");
      localStorage.setItem("token", data?.token);
      navigate("/portal");
    } catch (err) {
      console.log(err);
      toast.error(
        err.response.data || "Terjadi kesalahan. Silakan coba lagi nanti."
      );
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, action) => {
      onLogin(values);
      action.resetForm();
    },
  });

  return (
    <Box
      sx={{
        minHeight: "100vh",
        minWidth: "100vw",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Motion>
        <div>
          <form onSubmit={formik.handleSubmit}>
            <Box
              sx={{
                minWidth: { md: "500px", sm: "80vw", xs: "90vw" },
                minHeight: "400px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                padding: "50px",
                bgcolor: "rgba(0, 0, 0, 0.1)",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  maxWidth: "200px",
                }}
              >
                <img
                  src="https://adm.darussalamkasomalang.com/static/media/logo_wide.cef404e27f8f22aecf5a.png"
                  alt="icon"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                />
              </Box>
              <FieldInput name="email" formik={formik} label="Email" />
              <FieldInput name="password" formik={formik} label="Password" />
              <Button
                variant="contained"
                fullWidth
                type="submit"
                color="primary"
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Login"
                )}
              </Button>
            </Box>
          </form>
        </div>
      </Motion>
    </Box>
  );
};
