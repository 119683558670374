import "../src/style/global.css";
import "react-toastify/dist/ReactToastify.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { HomePage } from "./pages/HomePage";
import { RegisterPage } from "./pages/RegisterPage";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { LoginPage } from "./pages/LoginPage";
import { HomeAccountPage } from "./pages/Account/HomePage";
import { ExamCardPage } from "./pages/Account/ExamCardPage";
import { CheckoutPage } from "./pages/Account/Checkout";
import { SchedulePage } from "./pages/SchedulePage";
import { CostPage } from "./pages/CostPage";
import { ClothesPage } from "./pages/ClothesPage";
import { PortalHome } from "./pages/Portal/PortalHome";
import { NavbarLogin } from "./pages/Portal/NavbarLogin";
import { PaymentPortal } from "./pages/Portal/Payment";
import { RegisterPortal } from "./pages/Portal/Register";
import { TutorialPage } from "./pages/TutorialPage";
import { FaqPage } from "./pages/FaqPage";
import { PaymentPage } from "./pages/PaymentPage";
import { ConfirmPaymentPage } from "./pages/Portal/Confirm";
import { LoginPortalPage } from "./pages/Portal/Login";
import { HeaderAccountPage } from "./pages/Account/Header";
import { RegisteredPortal } from "./pages/Portal/Registered";
import { ClosedAccountPage } from "./pages/Account/Closed";
import { ToastContainer } from "react-toastify";
import { KwitansiPortal } from "./pages/Portal/Kwitansi";
import { PaidPaymentPortal } from "./pages/Portal/PaidPayment";
import { PaymentRegPortal } from "./pages/Portal/PaymentReg";
import { IntructionPage } from "./pages/IntructionPage";
import { PendingRegPortal } from "./pages/Portal/PendingReg";
import { TestCheckPage } from "./pages/Portal/TestCheck";
import { TestKnowledgePage } from "./pages/Portal/TestKnowledge";
import { TestInterviewPage } from "./pages/Portal/TestInterview";
import { ExamInfoPage } from "./pages/Account/ExamInfoPage";
import { StudentTestPortal } from "./pages/Portal/TestStudent";
import { EquipmentPortal } from "./pages/Portal/Equipment";
import { FolderPage } from "./pages/Account/FolderPage";
import { UploadPaymentReg } from "./components/Portal/register-pending/UploadPaymentReg";
import { Analytics } from "./pages/GA";

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#0F3B3D",
      },
      secondary: {
        main: "#256D70",
      },
    },
  });

  const router = createBrowserRouter([
    {
      element: <Analytics />,
      children: [
        { path: "/", element: <HomePage /> },
        { path: "/register", element: <RegisterPage /> },
        { path: "/login", element: <LoginPage /> },
        { path: "/schedule", element: <SchedulePage /> },
        { path: "/cost", element: <CostPage /> },
        { path: "/clothes", element: <ClothesPage /> },
        { path: "/tutorial", element: <TutorialPage /> },
        { path: "/faq", element: <FaqPage /> },
        { path: "/intruction", element: <IntructionPage /> },
        { path: "/payment/:token", element: <PaymentPage /> },
        { path: "/confirm/:token", element: <ConfirmPaymentPage /> },
      ],
    },
    {
      element: <NavbarLogin />,
      children: [
        { path: "/portal", element: <PortalHome /> },
        { path: "/portal/payment", element: <PaymentPortal /> },
        { path: "/portal/payment-register", element: <PaymentRegPortal /> },
        { path: "/portal/pending-register", element: <PendingRegPortal /> },
        { path: "/portal/pending-register/:id", element: <UploadPaymentReg /> },
        { path: "/portal/student-register", element: <RegisteredPortal /> },
        { path: "/portal/student-payment", element: <PaidPaymentPortal /> },
        { path: "/portal/test-check", element: <TestCheckPage /> },
        { path: "/portal/test-knowledge", element: <TestKnowledgePage /> },
        { path: "/portal/test-interview", element: <TestInterviewPage /> },
        { path: "/portal/test-student", element: <StudentTestPortal /> },
        { path: "/portal/equipment", element: <EquipmentPortal /> },
        { path: "/portal/register", element: <RegisterPortal /> },
        { path: "/portal/kwitansi/:id", element: <KwitansiPortal /> },
      ],
    },
    { path: "/portal/login", element: <LoginPortalPage /> },
    {
      element: <HeaderAccountPage />,
      children: [
        { path: "/:token", element: <HomeAccountPage /> },
        { path: "/:token/exam-card", element: <ExamCardPage /> },
        { path: "/:token/exam-info", element: <ExamInfoPage /> },
        { path: "/:token/bill", element: <CheckoutPage /> },
        { path: "/:token/folder", element: <FolderPage /> },
        { path: "/:token/close", element: <ClosedAccountPage /> },
      ],
    },
  ]);

  return (
    <div style={{ backgroundColor: "#f3f6fa", minHeight: "100vh" }}>
      <ToastContainer />
      <ThemeProvider theme={theme}>
        <RouterProvider router={router} />
      </ThemeProvider>
    </div>
  );
}

export default App;
