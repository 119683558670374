import ReactGA from "react-ga4";

const initAnalytics = () => {
  if (process.env.NODE_ENV === "production") {
    ReactGA.initialize("G-D2B83N6P39");
  }
};

const trackEvent = (eventName, params = {}) => {
  if (process.env.NODE_ENV === "production") {
    ReactGA.event(eventName, params);
  }
};

const trackPageView = (path) => {
  if (process.env.NODE_ENV === "production") {
    ReactGA.send({ hitType: "pageview", page: path });
  }
};

export { initAnalytics, trackEvent, trackPageView };
